//切位订单详情
import Q from 'q';
import moment from 'moment';

import trains_order_getChangeOrderPaymentInfo from '@/lib/data-service/trains/trains_order_getChangeOrderPaymentInfo.js'

import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.0.15/index.vue';
// import PaymentMethodSelector from "@/lib/common-service/getPayModule";

export default {
  components: {
    PaymentMethodSelector
  },
  data() {
    return {
      loading: false,
      detail: {},
      timer: null,
    };
  },
  computed: {

  },
  methods: {
    req_payment_detail (orderNo) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_getChangeOrderPaymentInfo({orderNo});
        })
        .then(function (res) {
          const detail = res
          _this.count_down(detail);
          _this.detail = detail;
          _this.loading = false;
          _this.timer = setInterval(function () {
            _this.count_down(_this.detail);
          }, 1000);
          _this.newPaySubmit()
        })
        .catch(function (data) {
          _this.$router.replace({
            name: 'distributor-train-back-train-change-list',
          })
        })
    },
    count_down (detail) {
      if (detail.seconds > 0) {

        detail.seconds -= 1;
        let minute = Math.floor(detail.seconds / 60);
        minute = minute <= 9 ? '0' + minute : minute;

        let second = Math.floor(detail.seconds % 60);
        second = second <= 9 ? '0' + second : second;

        detail.secondsText = `00天00时${minute}分${second}秒`;
        // '00天' + '00时' + Math.floor(seconds / 60) + '分' + Math.floor(seconds % 60) + '秒'
         if (detail.seconds <= 0) {
          this.req_payment_detail(this.$route.query.orderNo);
         }
      } else {
        detail.secondsText = '0天0时0分0秒';
        clearInterval(this.timer);
      }
    },
    newPaySubmit() {
      const __this = this;
      this.$nextTick(() => {
        this.$refs.aPaymentMethodSelector.init({
          situationParameter: {
            '000': {
              companyId: this.detail.companyId,
              clubId: this.detail.clubId,//中间社id
              orderNo: this.detail.orderNo, //订单编号
              businessContent: this.detail.businessContent, //不用管里面的值是什么，订单详情返回的businessContent,直接传入到这里  this.orderInfo.businessContent
              subject: this.detail.productName, //
              orderAmount: this.detail.orderAmount, //订单金额，用于计算可以用哪些支付方式支付
              count_down: {
                enable: true,//是否启用倒计时，默认值为true
                pay_remaining_seconds: this.detail.seconds, //支付剩余时间，以秒为单位
              },
            },
          },

          //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
          before_pay_event_handler() {
            // alert(`不能够支付！原因：XXX`);
            // return Q.reject();
            return Q.resolve();
          },
          //支付成功事件处理方法
          pay_successful_event_handler() {
            console.log(`pay_successful_event_handler`);
            // 预定
            __this.$router.push({
              name: 'distributor-train-front-pay-success',
              query: {
                orderNo: JSON.parse(__this.detail.businessContent).trainChangeNo,
                fromStationCode: __this.detail.fromStationCode,
                toStationCode: __this.detail.toStationCode,
                time: moment(__this.detail.startDate) <= moment() ? moment(__this.detail.startDate).add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
                status: 'success',
                type: 'change',
              }
            })
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            console.log(`pay_failed_event_handler`);
          },
        });
      })
    },
  },
  watch: {

  },
  mounted() {
    this.req_payment_detail(this.$route.query.orderNo);
  },
  activated () {

  }

}
